import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Freecause`}</h1>
    <p><em parentName="p">{`Note: this page is under construction. Please check back soon.`}</em></p>
    <h2><em parentName="h2">{`Engineering Team, 2010-2011. `}<br />{` Based in Boston, MA. Acquired by Rakuten.`}</em></h2>
    <h2>{`Software Engineer / Implementation Manager, April 2010 - June 2011`}</h2>
    <p>{`Responsible for developing and shipping browser extensions and creating web services for them to interact with.`}</p>
    <p>{`Used PHP, MySQL, and javascript to prototype and built client-facing web pages and reporting interfaces, internal apps, supported legacy code (minor perl hacking), and troubleshooted problems with clients. Kept morale high by icing coworkers and initiating nerf gun fights. Freecause was acquired by Rakuten and renamed Rakuten Loyalty.`}</p>
    <p>{`Below: we likely made many of these toolbars`}</p>
    <p>{`Left Freecause in 2011 to start `}<a parentName="p" {...{
        "href": "/startup/pradux"
      }}>{`Pradux`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      